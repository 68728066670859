var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('page-content',{attrs:{"title":"Cartões de estatísticas","breadcrumbTitle":true,"breadcrumb":[
      {
        title: 'Main',
      },
      {
        title: 'Widgets',
      },
      {
        title: 'Cards',
      },
    ]},scopedSlots:_vm._u([{key:"desc",fn:function(){return [_c('p',{staticClass:"h5 mb-0 mt-24 hp-text-color-black-0"},[_vm._v(" Você pode encontrar cartões de estatísticas especiais que criamos para o modelo Admin ")])]},proxy:true}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12","sm":"6","xl":"3"}},[_c('order-card')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12","sm":"6","xl":"3"}},[_c('users-card')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12","sm":"6","xl":"3"}},[_c('subscribe-card')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12","sm":"6","xl":"3"}},[_c('ticket-card')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12","xl":"4"}},[_c('traffic-card')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12","xl":"8"}},[_c('statistics-card')],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"8"}},[_c('b-row',[_c('b-col',{staticClass:"mb-32",attrs:{"cols":"6","md":"3"}},[_c('order-vertical-card')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"6","md":"3"}},[_c('users-vertical-card')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"6","md":"3"}},[_c('subscribe-vertical-card')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"6","md":"3"}},[_c('ticket-vertical-card')],1)],1)],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12","xl":"4"}},[_c('revenue-card')],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }