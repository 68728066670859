import { render, staticRenderFns } from "./UsersVerticalCard.vue?vue&type=template&id=32241959"
import script from "./UsersVerticalCard.vue?vue&type=script&lang=js"
export * from "./UsersVerticalCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports